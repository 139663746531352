<template>
    <div v-if="isLoggedIn" class="container page">

        <div v-if="!isDone" class="card card-default">
            <b-form @submit.prevent="onSubmitIdea" novalidate>
                <div class="card-header">
                    <h3>Submit a New Idea</h3>
                </div>
                <div class="card-body">
                    <div class="mb-3">
                        <content-db contentKey="topmsgideabox" :isCache="true"></content-db>
                    </div>
                    <b-form-group id="categoryGrp" label-for="categoryTxt" label="Category:" :invalid-feedback="fIdeabox.errors.category" description="">
                        <b-form-select id="categoryTxt" v-model="fIdeabox.form.category" :state="fIdeabox.state.category">
                            <b-form-select-option value="">-- Select a Value --</b-form-select-option>
                            <b-form-select-option value="The next big idea">The next big idea</b-form-select-option>
                            <b-form-select-option value="Improve rewards and recognition">Improve rewards and recognition</b-form-select-option>
                            <b-form-select-option value="Current challenges">Current challenges</b-form-select-option>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group id="titleGrp" label-for="titleTxt" label="Title:" :invalid-feedback="fIdeabox.errors.title">
                        <b-form-input id="titleTxt" v-model="fIdeabox.form.title" :state="fIdeabox.state.title" placeholder="" maxlength="50" />
                    </b-form-group>

                    <b-form-group id="ideaGrp" label-for="ideaTxt" label="Your Idea:" :description="2000-fIdeabox.form.idea.length + ' characters remaining'" :invalid-feedback="fIdeabox.errors.idea">
                        <b-form-textarea id="ideaTxt" v-model="fIdeabox.form.idea" :state="fIdeabox.state.idea" placeholder="" maxlength="2000" rows="10" max-rows="16" />
                    </b-form-group>

                    <hr />

                </div>
                <div class="card-footer">
                    <div class="text-right"><b-button type="submit" variant="primary">Submit Idea</b-button></div>
                </div>
            </b-form>
        </div>

        <section v-else class="card card-default">
            <div class="card-header">
                <h4>Idea Box</h4>
                
            </div>
            <div class="card-body">
                <b-alert show variant="success">Thank you!</b-alert>
                <br />
                <br />
                <div>Your idea has been submitted successfully</div>
                <br />
                <br />
                <hr />
                <div class="text-right"><a class="btn btn-primary" href="/">Home</a></div>
            </div>
        </section>

    </div>

</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    //import sjData from '@/sjUtilData'
    //import ContentDb from '@/components/ContentDb';

    export default {
        name: 'IdeaBox',
        //components: { ContentDb },
        data() {
            return {
                isDone: false,
                fIdeabox: {
                    form: { category: '', title: '', idea: '' }
                },
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member;
            },
            isLoggedIn() {
                return this.$store.state.isLoggedIn;
            }
        },

        created() {
            this.formSetState(this.fIdeabox, true, true) //formObj, resetState, resetForm
        },
        mounted() {
        },
        methods: {
            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },

            onSubmitIdea() {
                this.formSetState(this.fIdeabox, true);//formObj, resetState, resetForm

                return axios
                    .post(
                        '/api/idea/ideasubmit',
                        this.fIdeabox.form
                    )
                    .then(( /*{data}*/ ) => {
                        this.isDone = true;
                    })
                    .catch((error) => {
                        if (error && error.response) {
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                            if (error.response.data.formErrors) {
                                this.fIdeabox.errors = error.response.data.formErrors;
                                this.formSetState(this.fIdeabox);
                            }
                        } else {
                            this.msgBox('Unexpected Error');
                        }
                    });
            }
        }

    }
</script>
