<template>
    <div :id="appClass">
        <div class="loadingOverlay" v-show="isDataLoading">Loading&#8230;</div>
        <app-header />
        <app-nav />
        <router-view />
        <app-footer />
        <b-modal id="modalPrivacy" scrollable ok-only size="lg" title="Privacy Policy">
            <content-db contentKey="privacy" :isCache="true"></content-db>
        </b-modal>
        <b-modal id="modalTerms" scrollable ok-only size="lg" title="Terms and Conditions">
            <content-db contentKey="terms" :isCache="true"></content-db>
        </b-modal>
        <b-modal id="modalDisclaimer" scrollable ok-only size="lg" title="Disclaimer">
            <content-db contentKey="disclaimer" :isCache="true"></content-db>
        </b-modal>
        <section v-if="!cookiePolicyAr[0]">
            <div id="cookieAcceptance" style="position:fixed; top: 0px; left: 0px; right: 0px; padding: 25px; z-index: 1030; background-color: #fff; box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.5); border-radius: 0px; transition: transform .25s ease-in-out,opacity .25s ease-in-out;">
                <!-- <h4>Cookie Settings</h4> -->
                <div class="container-fluid">
                    <p>
                        We use cookies and similar technologies to help personalise content, tailor and measure our communications, and provide a better experience.
                        By accepting or turning an option on in Cookie Preferences, you agree to this as outlined in our
                        <a href="privacy" target="_blank"><u>Privacy Policy</u></a>.
                        To change preferences or withdraw consent, please update your Cookie Preferences.
                    </p>
                    <div class="text-right">
                        <button type="button" @click="loadCookiePolicy();$bvModal.show('modalCookiePolicyPreferences')" class="btn btn-link mr-3">Cookie Preferences</button>
                        <button type="button" @click="cookiePolicyAr[0]=true;saveCookiePolicy();" class="btn btn-primary">ACCEPT</button>
                        <!--<button onclick="$('#modalPolicyAcceptance').modal('show');" class="btn btn-secondary" style="margin-right: 30px">Cookie Preferences</button>
                    <button onclick="cookiePolAccept.hide();" class="btn btn-primary">ACCEPT</button>-->
                    </div>
                </div>
            </div>
        </section>

        <b-modal id="modalCookiePolicyPreferences" scrollable hide-footer size="lg" no-close-on-backdrop title="Your Preferences">
            <h3>Your Preferences</h3>
            <p>You can switch off cookie types by clicking or pressing on their name. Mandatory Cookies cannot be disabled. You can change these preferences anytime.</p>
            <hr />

            <div><b-form-checkbox id="cookiePolAccept0" switch disabled :checked="true"><b>Mandatory Cookies</b></b-form-checkbox></div>
            <div>These cookies are necessary for the website to function and can't be switched off in our systems. They are usually only set in response to actions you made which result in a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies but some parts of the site may not work as a result.</div>

            <hr />

            <div><b-form-checkbox id="cookiePolAccept1" switch v-model="cookiePolicyAr[1]"><b>Functional Cookies</b></b-form-checkbox></div>
            <div>
                These cookies allow the provision of enhance functionality and personalization, such as videos and live chats.
                They may be set by us or by third party providers whose services we have added to our pages. If you don't allow these cookies, then some or all of these functionalities may not function properly.
            </div>

            <template v-if="false">
                <div><b-form-checkbox id="cookiePolAccept2" switch v-model="cookiePolicyAr[1]"><b>Advertising Cookies</b></b-form-checkbox></div>
                <div>These cookies are set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant ads on other sites. They work by uniquely identifying your browser and device. If you don't allow these cookies, you will not experience our targeted advertising across different websites as a result of these cookies.</div>
            </template>

            <hr />
            <div class="text-right">
                <button type="button" @click="loadCookiePolicy();$bvModal.hide('modalCookiePolicyPreferences')" class="btn btn-link mr-3">Cancel</button>
                <button type="button" @click="cookiePolicyAr[0]=true;saveCookiePolicy();$bvModal.hide('modalCookiePolicyPreferences')" class="btn btn-primary">ACCEPT</button>
                <button type="button" @click="cookiePolicyAr=[false,true,false];saveCookiePolicy();$bvModal.hide('modalCookiePolicyPreferences')" class="btn btn-link float-left">Reset</button>
                <!--
                <button onclick="$('#modalPolicyAcceptance').modal('hide');" style="margin-right: 30px">Cancel</button>
                <button onclick="cookiePolAccept.setCookiePolicy(false);" class="btn btn-primary">ACCEPT</button>
                <button style="float:left" onclick="cookiePolAccept.hide(true);" class="btn btn-link">Reset</button>
            -->
            </div>
        </b-modal>

    </div>
</template>

<script>
	import sjUtils from './sjUtils';

    import './assets/scss/site.scss'
    import AppHeader from '@/components/AppHeader'
    import AppNav from '@/components/AppNav'
    import AppFooter from '@/components/AppFooter'
	import ContentDb from '@/components/ContentDb';


    export default {
        components: { AppHeader, AppNav, AppFooter, ContentDb },
        computed: {
			isDataLoading() {
				return this.$store.state.dataLoadingCount != 0;
			},
            appClass() {
                return this.$store.state.siteTemplateComponent;
            },
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
            member() {
                return this.$store.state.memberStore.member;
            },
            doForceMemberUpdate() {
                if (!this.isLoggedIn || (this.member.cellNum !== '' && this.member.email !== '')) return '';
                return this.$route.name !== 'MyProfileUpdate' ? this.$route.name : ''; //if either cellnum or email is blank, then we need them to force them to accept terms and conditions
            },
            routeName() {
                return this.$route.name;
            }

            //siteTemplateComponent() {
            //    return this.$store.state.memberStore.siteTemplateComponent
            //}
        },
        watch: {
            routeName: function () {
                //console.log('routeName: ' + this.$route.name + ' ' + newVal);
                //this.isLoggedIn && this.member.cellNum === '' || this.member.email === '' && this.$route.name != 'MyProfileUpdate'
            },
            doForceMemberUpdate: function (newVal) {
                console.log('doForceMemberUpdate: ' + this.$route.name + ' ' + newVal);
                //            //debugger;
                if (newVal != '') {
                    this.$router.replace({ name: 'MyProfileUpdate', params: { urlParam: 'update' } })
                }
            },
        },
        created() {
			this.loadCookiePolicy();

            if (this.doForceMemberUpdate != '') {
                console.log('doForceMemberUpdate*: ' + this.$route.name + ' ');
                this.$router.replace({ name: 'MyProfileUpdate', params: { urlParam: 'update' } })
            }
        },
		data() {
			return {
				cookiePolicyAr: [false, true, false],
			}
		},
		methods: {
			loadCookiePolicy() {
				//let cPol = sessionStorage.getItem('cPol');
				let cPol = sjUtils.getCookie("cPol");
				if (!cPol || cPol.length !== 3) {
					this.cookiePolicyAr = [false, true, false];
					this.saveCookiePolicy();
				} else {
					let cPolAr = cPol.split('');
					this.cookiePolicyAr = [cPolAr[0] === 'y', cPolAr[1] === 'y', cPolAr[2] === 'y'];
				}
			},

			saveCookiePolicy() {
				let cPolAr = this.cookiePolicyAr;
				if (!cPolAr || cPolAr.length !== 3) cPolAr = [false, true, false];
				sjUtils.setCookie("cPol", (cPolAr[0] ? 'y' : '-') + (cPolAr[1] ? 'y' : '-') + (cPolAr[2] ? 'y' : '-'));
				this.loadCookiePolicy();
			}
		}

    }
</script>

