<template v-if="isLoggedIn">
    <div class="container page">

        <div class="pgrecentrecognition">
            <div class="title">CELEBRATE WITH US</div>
            <content-db contentKey="topmsgcelebrations" :isCache="true" v-on:title-onset="contentTitle=$event"></content-db>


            <template v-if="reportBday.length!==0">
                <div class="title">TODAY'S BIRTHDAYS</div>
                <div class="row header">
                    <div class="col-sm-2"></div>
                    <div class="col-sm-3">Name</div>
                    <div class="col-sm-3">Division</div>
                    <div class="col-sm-4"></div>
                </div>
                <div class="ruleheader"></div>
                <div v-for="r in reportBday" :key="r._rowNum">
                    <div class="row">
                        <div class="col-sm-2 text-center"><img class="w-100" :src="('/profilepic/' + r.picId)" /></div>
                        <div class="col-sm-3 membername">{{ r.firstName + ' ' + r.surname }}</div>
                        <div class="col-sm-3 country">{{ r.division }}</div>
                        <div class="col-sm-4 buttons">
                            <template v-if="r.memberId.sjRemoveIntHash() != 0">
                                <!--<button v-on:click="onLinkOut('/nominate',r.memberId)" class="btn btn-primary">Nominate</button>-->
                                <button v-if="r.interest1" v-on:click="onLinkOut('BDAY','/gettoknow',r.memberId)" class="btn btn-primary mr-1 mb-2">Get to Know</button>
                                <button v-on:click="onLinkOut('BDAY','/recognise',r.memberId)" class="btn btn-primary mr-1 mb-2">Celebrate</button>
                                <button v-on:click="onLinkOut('BDAY','/rewardpayitforward',r.memberId)" class="btn btn-primary mr-1 mb-2">Pay It Forward</button>
                                <!--
        <router-link v-if="r.interest1" :to="`/gettoknow/${r.memberId}`" type="button" class="btn btn-primary mb-2 mr-1">Get to Know</router-link>
        <router-link :to="`/recognise/${r.memberId}`" class="btn btn-primary mb-2 mr-1">Shout out</router-link>
        <router-link v-if="isManager" :to="`/reward/${r.memberId}`" class="btn btn-primary mb-2">Recognise</router-link>
                                    -->
                            </template>
                            <template v-else>
                                This member has not registered
                            </template>
                        </div>
                    </div>
                    <div class="ruledata"></div>
                </div>
            </template>

            <template v-if="reportAnniv.length!==0">
                <div class="title">TODAY'S WORK ANNIVERSARIES</div>
                <div class="row header">
                    <div class="col-sm-2"></div>
                    <div class="col-sm-3">Name</div>
                    <div class="col-sm-2">Division</div>
                    <div class="col-sm-1">Years</div>
                    <div class="col-sm-4"></div>
                </div>
                <div class="ruleheader"></div>
                <div v-for="r in reportAnniv" :key="r._rowNum">
                    <div class="row">
                        <div class="col-sm-2 text-center"><img class="w-100" :src="('/profilepic/' + r.picId)" /></div>
                        <div class="col-sm-3 membername">{{ r.firstName + ' ' + r.surname }}</div>
                        <div class="col-sm-2 country">{{ r.division }}</div>
                        <div class="col-sm-1 country">{{ r.years + ' year' + (r.years == 1 ? '' : 's')}}</div>
                        <div class="col-sm-4 buttons">
                            <template v-if="r.memberId.sjRemoveIntHash() != 0">
                                <!--<button v-on:click="onLinkOut('/nominate',r.memberId)" class="btn btn-primary">Nominate</button>-->
                                <button v-if="r.interest1" v-on:click="onLinkOut('ANNIV','/gettoknow',r.memberId)" class="btn btn-primary mr-1 mb-2">Get to Know</button>
                                <button v-on:click="onLinkOut('ANNIV','/recognise',r.memberId)" class="btn btn-primary mr-1 mb-2">Celebrate</button>
                                <button v-on:click="onLinkOut('ANNIV','/rewardpayitforward',r.memberId)" class="btn btn-primary mr-1 mb-2">Pay It Forward</button>
                                <!-- <router-link v-if="isManager" :to="`/reward/${r.memberId}`" class="btn btn-primary mb-2">Recognise</router-link>
        -->
                            </template>
                            <template v-else>
                                This member has not registered
                            </template>

                        </div>
                    </div>
                    <div class="ruledata"></div>
                </div>
            </template>

            <div v-if="!isLoading && reportAnniv.length===0 && reportBday.length===0" class="title">Sorry we are not celebrating today</div>
            <div v-if="isLoading"><i>Loading...</i></div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    import sjData from '@/sjUtilData'
    import ContentDb from '@/components/ContentDb';

    export default {
        name: 'Celebrations',
        components: { ContentDb },
        data() {
            return {

                reportAnniv: [],
                reportBday: [],
                isLoading: false
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member
            },
            isManager() {
                return this.$store.state.memberStore.member.isManager
            },

            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
        },

        created() {
            this.loadReport();
        },
        mounted() {
            //axios.post('/api/member/memberget', {})
            //	.then(({ data }) => {
            //		//this.roles = data.roles;
            //		//this.noms = new sjData(data.noms).data;
            //		//this.displayPart = 1;
            //	})
            //	.catch((error) => {
            //		if (error) {
            //			this.msgBox(error.response.data.message);
            //		}
            //	})
        },
        watch: {

        },

        methods: {
            onLinkOut(what, whereto, memberId) {
                sessionStorage.setItem('tloid', memberId);
                sessionStorage.setItem('tlowhat', what);//tloid - temp link out id;
                this.$router.push(whereto)
            },

            loadReport() {
                this.isLoading = true;
                this.report = [];
                axios.post('/api/site/getcelebrations', {})
                    .then(({ data }) => {
                        this.isLoading = false;
                        let report = new sjData(data.report).data;
                        this.reportAnniv = report.filter(n => n.what === "ANNIV");
                        this.reportBday = report.filter(n => n.what === "BDAY");
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },


            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            }


        }

    }
</script>
