<template v-if="isLoggedIn">
    <div class="container-fluid page">

        <div class="card card-default" style="min-width:420px">
            <div class="card-header">
                <div class="row">
                    <div class="col-sm-6 font-weight-bold">
                        <h5>Idea Management</h5>
                    </div>

                    <div class="col-sm-3">

                    </div>
                    <div class="col-sm-3 text-right">
                        <b-button class="d-print-none mr-2" size="sm" @click.prevent="windowPrint" :disabled="report.length==0">Print</b-button>
                        <b-button class="d-print-none" size="sm" @click.prevent="exportToCSV" :disabled="report.length==0">Export to CSV</b-button>
                    </div>
                </div>

            </div>
            <div class="card-body">
                <div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-md-2 col-lg-1 control-label">From Date:</label>
                        <div class="col-sm-3 col-md-4 col-lg-3">
                            <b-input-group size="sm">
                                <b-input-group-prepend is-text>
                                    <b-icon icon="calendar"></b-icon>
                                </b-input-group-prepend>
                                <b-form-select :options="monthList" v-model="selMonthFrom">
                                    <!--<template v-slot:first>
                                        <b-form-select-option :value="null">-- All Periods --</b-form-select-option>
                                    </template>-->
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <label class="col-sm-3 col-md-2 col-lg-1 control-label">To Date:</label>
                        <div class="col-sm-3 col-md-4 col-lg-3">
                            <b-input-group size="sm">
                                <b-input-group-prepend is-text>
                                    <b-icon icon="calendar"></b-icon>
                                </b-input-group-prepend>
                                <b-form-select :options="monthList" v-model="selMonthTo">
                                    <!--<template v-slot:first>
                                        <b-form-select-option :value="null">-- All Periods --</b-form-select-option>
                                    </template>-->
                                </b-form-select>
                            </b-input-group>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-md-2 col-lg-1 control-label">Status:</label>
                        <div class="col-sm-7 col-md-7">
                            <b-form-radio-group id="selideaStatus" v-model="ideaStatus">
                                <b-form-radio value="N">Awaiting Review</b-form-radio>
                                <b-form-radio value="R">Rewarded</b-form-radio>
                                <b-form-radio value="C">Concluded</b-form-radio>

                            </b-form-radio-group>
                        </div>
                        <div class="col-sm-2 col-md-3">

                        </div>
                    </div>
                    <div class="row d-print-none">
                        <div class="col-sm-3 col-md-2 col-lg-1"></div>
                        <div class="col-sm-7 col-md-7">
                            <b-button @click="loadReport()" variant="primary">Load Report</b-button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div v-if="hasRunOnce">
            <div v-for="n in report" :key="n._rowNum">

                <section class="card card-default mt-3">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-5">
                                <b>{{ n.byMember + ' (' + n.byMemberNumber + ')' }}</b>
                            </div>
                            <div class="col-3">{{ n.byDivision }}</div>
                            <div class="col-4 text-right"><strong></strong> #{{ n.ideaBoxId.sjRemoveIntHash() }} <span class="pl-5"> {{ n.dateCreated.sjToDate('d MMM yyyy HH:mm') }}</span></div>
                            <!--<b-button v-b-toggle="'collapse-' + n._rowNum" variant="primary">Toggle Collapse</b-button>-->
                        </div>
                    </div>

                    <div class="card-body">
                        <!-- Nominee List -->
                        <div class="row mb-2">
                            <div class="col-lg-2 fldNameB">Category:</div>
                            <div class="col-lg-8 fldValueB">{{ n.category }}</div>
                            <div class="col-lg-2 text-right d-flex align-items-end justify-content-end" v-if="n.ideaStatus === 'N'"><b-button type="button" pill @click="selectIdea(n._rowNum)" variant="primary" size="sm" class="d-print-none">Review</b-button></div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-lg-2 fldNameB">Title:</div>
                            <div class="col-lg-10 fldValueB">{{ n.title }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-lg-2 fldNameB">Idea:</div>
                            <div class="col-lg-10 fldValueB">{{ n.idea }}</div>
                        </div>
                        <template v-if="n.ideaStatus == 'C' || n.ideaStatus == 'R'">
                            <div class="row mb-2">
                                <div class="col-lg-2 fldNameB">Action taken:</div>
                                <div class="col-lg-10 fldValueB">{{ n.actionStatus }}</div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-lg-2 fldNameB">Action by:</div>
                                <div class="col-lg-10 fldValueB">{{ n.actionByMember }} ({{ n.actionByMemberNumber }}) {{ n.dateActioned.sjToDate('d MMM yyyy') }}</div>
                            </div>
                        </template>
                        <template v-if="n.ideaStatus == 'R'">
                            <div class="row mb-2">
                                <div class="col-lg-2 fldNameB">Rewarded:</div>
                                <div class="col-lg-10 fldValueB">{{ n.rewardAmount }} NexCredz</div>
                            </div>
                        </template>

                    </div>
                </section>
            </div>
            <div v-if="isLoading"><i>Loading...</i></div>
            <div v-if="hasRunOnce && !isLoading && report && report.length===0"><i>(No ideas found)</i></div>
        </div>


        <!--
        **************************************************************************************************************************************************
                                                                    M O D A L S
        **************************************************************************************************************************************************
        -->
        <!-- Modal -->
        <div>
            <b-modal id="modalReview" title="REVIEW AND REWARD IDEA" hide-footer size="xl" no-close-on-backdrop>
                <section class="card card-default mt-3" v-if="selIdea">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-5">
                                <b>{{ selIdea.byMember + ' (' + selIdea.byMemberNumber + ')' }}</b>
                            </div>
                            <div class="col-3">{{ selIdea.byDivision }}</div>
                            <div class="col-4 text-right"><strong></strong> #{{ selIdea.ideaBoxId.sjRemoveIntHash() }} <span class="pl-5"> {{ selIdea.dateCreated.sjToDate('d MMM yyyy HH:mm') }}</span></div>
                            <!--<b-button v-b-toggle="'collapse-' + selIdea._rowNum" variant="primary">Toggle Collapse</b-button>-->
                        </div>
                    </div>

                    <div class="card-body">
                        <!-- Nominee List -->
                        <div class="row mb-2">
                            <div class="col-lg-2 fldNameB">Category:</div>
                            <div class="col-lg-8 fldValueB">{{ selIdea.category }}</div>
                            <div class="col-lg-2 text-right d-flex align-items-end justify-content-end"></div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-lg-2 fldNameB">Title:</div>
                            <div class="col-lg-10 fldValueB">{{ selIdea.title }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-lg-2 fldNameB">Idea:</div>
                            <div class="col-lg-10 fldValueB">{{ selIdea.idea }}</div>
                        </div>
                       
                    </div>
                </section>

                <hr />

                <!-- Review Form -->
                <section class="card card-default mt-3">
                    <div class="card-body">
                        <b-form @submit.prevent="onSubmitReview" novalidate>
                            <b-form-group id="actionStatusGrp" label-for="actionStatusTxt" label="Idea Comments:" :description="100-fAction.form.actionStatus.length + ' characters remaining'" :invalid-feedback="fAction.errors.actionStatus">
                                <b-form-textarea id="actionStatusTxt" v-model="fAction.form.actionStatus" :state="fAction.state.actionStatus" placeholder="" maxlength="100" rows="3" max-rows="3" />
                            </b-form-group>

                            <b-form-group id="pointsGrp" label-for="pointsTxt" label="Reward for this idea:" :invalid-feedback="fAction.errors.points" description="">
                                <b-form-select id="pointsTxt" v-model="fAction.form.points" :state="fAction.state.points">
                                    <b-form-select-option value="">-- Select a Value --</b-form-select-option>
                                    <b-form-select-option value="0">No Reward</b-form-select-option>
                                    <b-form-select-option value="50">50 NexCredz</b-form-select-option>
                                    <b-form-select-option value="100">100 NexCredz</b-form-select-option>
                                    <b-form-select-option value="150">150 NexCredz</b-form-select-option>
                                    <b-form-select-option value="200">200 NexCredz</b-form-select-option>
                                    <b-form-select-option value="250">250 NexCredz</b-form-select-option>
                                    <b-form-select-option value="300">300 NexCredz</b-form-select-option>
                                </b-form-select>
                            </b-form-group>

                            <div class="text-right"><b-button type="submit" variant="primary">Conclude</b-button></div>
                        </b-form>
                    </div>
                </section>

                <!--<div class="text-right"><b-button type="submit" variant="primary" @click="$bvModal.hide('modalChooseNominees')">Done</b-button></div>-->
            </b-modal>
        </div>
    </div>


</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    import sjData from '@/sjUtilData'


    export default {
        name: 'IdeaManagement',
        data() {
            return {
                monthList: [],
                selMonthFrom: null,
                selMonthTo: null,
                ideaStatus: 'N',
                report: [],
                isLoading: false,
                hasRunOnce: false,
                selIdea: null,
                fAction: {
                    form: { ideaBoxId: '', actionStatus: '', points: '' }
                },
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member
            },

            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
        },

        created() {
            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const result = [];
            let curYear = new Date().getFullYear();
            let curMonth = new Date().getMonth();

            for (let y = curYear; y >= curYear - 3; y--) {
                let mStart = y == curYear ? curMonth : 11;
                for (let m = mStart; m >= 0; m--) {
                    let t = months[m] + ' ' + y;
                    if (result.length === 0) this.selMonthFrom = t;
                    if (result.length === 0) this.selMonthTo = t;
                    result.push({ value: t, text: t });
                }
            }
            this.monthList = result;
            this.formSetState(this.fAction, true, true) //formObj, resetState, resetForm
            //this.loadReport();
        },
        mounted() {
            //axios.post('/api/member/memberget', {})
            //	.then(({ data }) => {
            //		//this.roles = data.roles;
            //		//this.noms = new sjData(data.noms).data;
            //		//this.displayPart = 1;
            //	})
            //	.catch((error) => {
            //		if (error) {
            //			this.msgBox(error.response.data.message);
            //		}
            //	})
        },
        watch: {
            //selMonth: function () {
            //    if (this.selMonth !== null) this.loadReport();
            //},
            //ideaStatus: function () {
            //    if (this.ideaStatus !== null) this.loadReport();
            //}
        },

        methods: {
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },
            loadReport() {
                this.isLoading = true;
                this.report = [];
                axios.post('/api/idea/idealist', { selMonthFrom: this.selMonthFrom, selMonthTo: this.selMonthTo, ideaStatus: this.ideaStatus })
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.hasRunOnce = true;
                        this.report = new sjData(data.report).data;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },
            exportToCSV() {
                let fx = function (val, fn) {
                    if (typeof fn === "undefined") fn = '';
                    let isNum = (fn === 'num' || typeof val === "number") && !isNaN(val);
                    try {
                        if (isNum) {
                            return val;
                        } else {
                            let s = val.sjReplaceAll("\r\n", " ~ ")
                                .sjReplaceAll("\r", " ~ ")
                                .sjReplaceAll("\n", " ~ ")
                                .sjReplaceAll("\t", "   ")
                                .trim();

                            //ph - phone number - split after 3 chars
                            if (fn == "ph" && s.length > 9) {
                                if (s.substr(0, 1) === "0") {
                                    s = s.sjReplaceAll(' ', '').sjReplaceAll('-', '').sjReplaceAll('(', '').sjReplaceAll(')', '').substr(0, 3) + " " + s.substr(3, 3) + " " + s.substr(6, 4);
                                } else {
                                    s = "'" + s;
                                }
                            }

                            //https://blog.zsec.uk/csv-dangers-mitigations/
                            if (s.indexOf("@") === 1 || s.indexOf("+") === 1 || s.indexOf("-") === 1 || s.indexOf("=") === 1 || s.indexOf("|") === 1 || s.indexOf("%") === 1) {
                                s = "'" + s.sjReplaceAll("|", "\\|");
                            }

                            s = "\"" + s.sjReplaceAll("\"", "\"\"") + "\"";
                            return s;
                        }
                    } catch {
                        return 'Error';
                    }
                }

                let csv = 'Ref,Date,Nominee ID,Nominee Name,Nominee Division,Nominated For,Reason,Nominated By ID,Nominated By,Nominated By Division,Review By ID,Review By Name,Review By Division,Rating,Points,Quarterly Motivation\r\n';

                for (var n = 0; n < this.report.length; n++) {
                    let f = this.report[n];
                    //debugger;
                    csv += f.nomSerialNum + '/' + f.nomId + ','
                        + fx(f.dateCreated.sjToDate('d MMM yyyy HH:mm')) + ','
                        + fx(f.nomineeMemberNumber) + ','
                        + fx(f.nomineeMember) + ','
                        + fx(f.nomineeDivision) + ','
                        + fx(f.nomCat) + ','
                        + fx(f.reason) + ','
                        + fx(f.byMemberNumber) + ','
                        + fx(f.byMember) + ','
                        + fx(f.byDivision) + ','
						+ fx(f.reviewByMemberNumber) + ','
						+ fx(f.reviewByMember) + ','
						+ fx(f.reviewByDivision) + ','
                        + fx(f.reviewRating) + ','
                        + fx(f.reviewPointsValue === 0 ? '' : f.reviewPointsValue) + ','
                        + fx(f.quarterlyMotivation) + '\r\n';
                }

                var pom = document.createElement('a');
                pom.href = URL.createObjectURL(new Blob(['\uFEFF', csv], { type: 'text/csv;charset=utf-8;' }));
                pom.setAttribute('download', 'report.csv');
                pom.click();
            },
            windowPrint() {
                window.print();
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },
            selectIdea(rowNum) {
                this.selIdea = this.report[rowNum];
                this.formSetState(this.fAction, true, true);//formObj, resetState, resetForm
                this.$bvModal.show('modalReview');
            },
            onSubmitReview() {
                this.formSetState(this.fAction, true);//formObj, resetState, resetForm
                this.fAction.form.ideaBoxId = this.selIdea.ideaBoxId;
                return axios
                    .post(
                        '/api/idea/donexusideareview',
                        this.fAction.form
                    )
                    .then(( /*{data}*/) => {
                        this.$bvModal.hide('modalReview');
                        this.loadReport();
                    })
                    .catch((error) => {
                        if (error && error.response) {
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                            if (error.response.data.formErrors) {
                                this.fAction.errors = error.response.data.formErrors;
                                this.formSetState(this.fAction);
                            }
                        } else {
                            this.msgBox('Unexpected Error');
                        }
                    });
            }

        }

    }
</script>
