<template v-if="isLoggedIn">
    <div class="container page">
        <div class="card card-default">
            <b-form @submit.prevent="onMemberUpdate" novalidate>
                <div class="card-header">
                    <h3>Data Upload</h3>
                </div>
                <div class="card-body">

                   
                    <div>Data Upload</div>
                    <div class="row mb-5">
                        <div class="col-sm-3" id="divUpdateProfilePic">
                            <img id="imgProfilePic" :src="profilePicTempSrc" class="img-fluid" />
                        </div>
                        <div class="col-sm-6">
                            <div>Upload text/csv file:</div>
                            <div>
                                <b-form-file v-model="fileData"></b-form-file>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="card-footer">
                    <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                </div>
            </b-form>
        </div>
        
    </div>

   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    //import sjData from '@/sjUtilData'
    //import ContentDb from '@/components/ContentDb';

    export default {
        name: 'MyProfile',
        components: { },
        data() {
            return {
                fileData: null,
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member;
            },
            isLoggedIn() {
                return this.$store.state.isLoggedIn;
            },
        },

        watch: {
            fileData: function (newfileData) {
                if (newfileData !== null) {
                    this.doFileUpload();
                }
            },

        },

        beforeRouteEnter(to, from, next) {
			next(vm => { // access to component instance via `vm` - i.e. use 'vm' instead of 'this'
               // console.log('*beforeRouteEnter');
                //console.log(to);
				//console.log(from);
                vm.doNavigation();
            });
        },

        created() {
            this.formSetState(this.fProfile, true, true) //formObj, resetState, resetForm
        },
        mounted() {
            this.doGetMember();

            //axios.post('/api/member/memberget', {})
            //	.then(({ data }) => {
            //		//this.roles = data.roles;
            //		//this.noms = new sjData(data.noms).data;
            //		//this.displayPart = 1;
            //	})
            //	.catch((error) => {
            //		if (error) {
            //			this.msgBox(error.response.data.message);
            //		}
            //	})
        },
        methods: {
            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },
            newPicTemp() {
                this.picSfx = ((new Date).getTime() - 1);
            },

            doNavigation() {
                const param = typeof this.$route.params.urlParam === 'undefined' ? '' : this.$route.params.urlParam;//  this.urlParam;
                console.log('doNavigation route param:');
                console.log(param);

                if (param === 'update') {
                    this.pgMode = 'edit'
                } else {
                    this.pgMode = 'view'
                }
            },

            doFileUpload() {
                let formData = new FormData();

                formData.append('fileData', this.fileData, this.fileData.name);
				axios.post('/datauploadapi/dataupload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then(() => {
                        this.newPicTemp();
                    })
                    .catch((error) => {
						this.msgBox(error.response.data.message);
                    });
            },

            onMemberUpdate() {
                
                return axios
                    .post(
                        '/api/member/memberupdate',
                        this.fProfile.form
                    )
                    .then(() => {
                        this.msgBox('Thank you for updating your details.');
                    })
                    .catch((error) => {
						this.msgBox(error.response.data.message);
                    });
            }
        }

    }
</script>
