<template v-if="isLoggedIn">
    <div class="container-fluid page myRecognition">

        <div class="headMyRecognition row">
            <div class="col-sm-9">MY RECOGNITION</div>
            <div class="col-sm-3 text-right">
                <!--<b-button class="d-print-none mr-2" size="sm" @click.prevent="windowPrint" :disabled="reportTo.length==0">Print</b-button>-->
            </div>
        </div>


        <div class="info">
            <div class="row">
                <div class="col-sm-6 col-md-5 col-lg-3 d-flex align-items-center">
                    <b-input-group size="sm">
                        <b-input-group-prepend is-text>
                            <b-icon icon="calendar"></b-icon>
                        </b-input-group-prepend>
                        <b-form-select :options="monthList" v-model="selMonth">
                            <!--<template v-slot:first>
                            <b-form-select-option :value="null">-- All Periods --</b-form-select-option>
                        </template>-->
                        </b-form-select>
                    </b-input-group>
                </div>
            </div>
        </div>


        <b-tabs v-model="tabIndex" lazy nav-class="recognitionTabs mt-3 pt-2">
            <b-tab title="Recognition Received">
                <div class="container-fluid">
                    <div class="headRecognitionSent row">
                        <div class="col-sm-6 d-flex align-items-center">RECOGNITION RECEIVED</div><!-- To this member -->
                        <div class="col-sm-6 d-flex align-items-center justify-content-end">
                            <template v-if="isShowMyRewards">
                                <span style="font-size: 2.5rem"><b-icon icon="gift" class="rounded-circle mr-2" style="padding:6px; color:#e0212c; background-color:#fff"></b-icon></span>
                                Rewards:
                                <strong class="ml-1 mr-4">{{rewardCountTo}}</strong>
                            </template>
                            <span style="font-size: 2.5rem"><b-icon icon="envelope" class="rounded-circle mr-2" style="padding:6px; color:#e0212c; background-color:#fff"></b-icon></span>
                            e-Cards: <strong class="ml-1 mr-4">{{msgCountTo}}</strong>
                            <!--<b-button class="d-print-none mr-2" size="sm" @click.prevent="windowPrint" :disabled="reportTo.length==0">Print</b-button>-->
                            <b-button v-if="reportFrom.length!==0" class="d-print-none" size="sm" @click.prevent="exportToCSV('to')" variant="primary" title="Export to CSV">CSV</b-button>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <div class="myrecContainer">

                            <div><b><i>{{ selMonth }}</i></b></div>
                            <hr />
                            <div v-for="f in reportFrom" :key="f._rowNum" class="row rec" @click="recClick(true,f._rowNum);">
                                <div class="col-sm-1 d-flex align-items-center" style="font-size: 1.5rem;">
                                    <b-icon :icon="(f.points == 0 ? 'envelope' : 'gift')"></b-icon>
                                </div>
                                <div class="col-sm-1 d-flex align-items-center">
                                    <img class="w-100" :src="('/profilepic/' + f.picId)" />
                                </div>
                                <div class="col-sm-3 d-flex align-items-center">
                                    {{ f.value }}
                                </div>
                                <div class="col-sm-3 d-flex align-items-center">
                                    {{ f.memberName }}
                                </div>
                                <div class="col-sm-3 d-flex align-items-center">
                                    {{ f.date.sjToDate('d/MM/yy') }}
                                </div>
                                <div class="col-sm-1 d-flex align-items-center justify-content-right">
                                    <a href="#" @click.prevent="recClick(true,f._rowNum)"><b-icon icon="three-dots-vertical"></b-icon></a>
                                </div>
                            </div>

                            <div v-if="isLoading"><i>Loading...</i></div>
                            <div v-if="!isLoading && reportFrom.length==0"><i>No recognitions received for {{selMonth}}</i><hr /></div>

                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="myrecContainer">

                            <div v-if="selRecFrom !== null" class="recCard">
                                <div style="background-color:#000">
                                    <!--<img src="../assets/image/email-headlogo.png" class="img-fluid" />-->
                                </div>
                                <div class="row">
                                    <div class="col-sm-5 text-right">
                                        <img :src="bannerFrom" class="img-fluid" style="border-right:solid 1px #aaa" />
                                    </div>
                                    <div class="col-sm-6">
                                        <div><br></div>
                                        <div style="padding:5px 4px;">Hi {{ $store.state.memberStore.member.firstname }}</div>
                                        <div><br></div>
                                        <div v-if="selRecFrom.type=='RwdN'">
                                            <div>You have received a Nomination Award!</div>
                                            <div><br></div>
                                            <div style="color:#e0212c;font-size:16pt; font-weight:bold;">{{ reasonDisplay(selRecFrom.value) }}</div>
                                            <div><br></div>
                                            <div class="preline">{{ selRecFrom.message }}</div>
                                        </div>
                                        <div v-else-if="selRecFrom.points==0">
                                            <div style="color:#e0212c;font-size:16pt; font-weight:bold;">
                                                {{ reasonDisplay(selRecFrom.value) }}
                                            </div>
                                            <div><br></div>
                                            <div class="preline">{{ selRecFrom.message }}</div>
                                        </div>
                                        <div v-else-if="selRecFrom.type=='PIF'">
                                            <div>You have received a Pay It Forward award of <strong>{{ selRecFrom.points }} NexCredz - {{ reasonDisplay(selRecFrom.value) }}</strong></div>
                                            <div><br></div>
                                            <div class="preline">{{ selRecFrom.message }}</div>
                                        </div>
                                        <div v-else>
                                            <div>You have been awarded  <strong>{{ selRecFrom.points }} NexCredz - {{ reasonDisplay(selRecFrom.value) }}</strong></div>
                                            <div><br></div>
                                            <div class="preline">{{ selRecFrom.message }}</div>
                                        </div>
                                        <div><br></div>
                                        <div>{{ selRecFrom.memberName }}</div>
                                    </div>
                                </div>
                                <!--<div style="background-color:#000" class="text-center">
                                    <img src="../assets/image/email-footlogo.png" class="img-fluid" />
                                </div>-->
                            </div>
                            <div v-else class="recCard">
                                <b>Click on a log entry to view e-card details.</b>
                            </div>

                        </div>

                    </div>
                </div>
            </b-tab>


            <b-tab title="Recognition Sent">
                <div class="container-fluid">
                    <div class="headRecognitionSent row">
                        <div class="col-sm-6 d-flex align-items-center">RECOGNITION SENT</div><!-- From this member -->
                        <div class="col-sm-6 d-flex align-items-center justify-content-end">
                            <template v-if="isShowMyRewards">
                                <span style="font-size: 2.5rem"><b-icon icon="gift" class="rounded-circle mr-2" style="padding:6px; color:#e0212c; background-color:#fff"></b-icon></span>
                                Rewards:
                                <strong class="ml-1 mr-4">{{rewardCountFrom}}</strong>
                            </template>
                            <span style="font-size: 2.5rem"><b-icon icon="envelope" class="rounded-circle mr-2" style="padding:6px; color:#e0212c; background-color:#fff"></b-icon></span>
                            e-Cards: <strong class="ml-1 mr-4">{{msgCountFrom}}</strong>
                            <!--<b-button class="d-print-none mr-2" size="sm" @click.prevent="windowPrint" :disabled="reportTo.length==0">Print</b-button>-->
                            <b-button v-if="reportTo.length!==0" class="d-print-none" size="sm" @click.prevent="exportToCSV('from')" variant="primary"  title="Export to CSV">CSV</b-button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <div class="myrecContainer">
                            <div><b><i>{{ selMonth }}</i></b></div>
                            <hr />
                            <div v-for="f in reportTo" :key="f._rowNum" class="row rec" @click="recClick(false,f._rowNum);">
                                <div class="col-sm-1 d-flex align-items-center" style="font-size: 1.5rem;">
                                    <b-icon :icon="(f.points == 0 ? 'envelope' : 'gift')"></b-icon>
                                </div>
                                <div class="col-sm-1 d-flex align-items-center">
                                    <img class="w-100" :src="('/profilepic/' + f.picId)" />

                                </div>
                                <div class="col-sm-3 d-flex align-items-center">
                                    {{ f.value }}
                                </div>
                                <div class="col-sm-3 d-flex align-items-center">
                                    {{ f.memberName }}
                                </div>
                                <div class="col-sm-3 d-flex align-items-center">
                                    {{ f.date.sjToDate('d/MM/yy') }}
                                </div>
                                <div class="col-sm-1 d-flex align-items-center justify-content-right">
                                    <a href="#" @click.prevent="recClick(false,f._rowNum)"><b-icon icon="three-dots-vertical"></b-icon></a>
                                </div>
                            </div>

                            <div v-if="isLoading"><i>Loading...</i></div>
                            <div v-if="!isLoading && reportTo.length==0"><i>No recognitions sent for {{selMonth}}</i><hr /></div>

                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="myrecContainer">

                            <div v-if="selRecTo !== null" class="recCard">
                                <div style="background-color:#000">
                                    <!--<img src="../assets/image/email-headlogo.png" class="img-fluid" />-->
                                </div>
                                <div class="row">
                                    <div class="col-sm-5 text-right">
                                        <img :src="bannerTo" class="img-fluid" style="border-right:solid 1px #aaa" />
                                    </div>
                                    <div class="col-sm-6">
                                        <div><br></div>
                                        <div style="padding:5px 4px;">Hi {{ selRecTo.memberName }}</div>
                                        <div><br></div>
                                        <div v-if="selRecTo.type=='RwdN'">
                                            <div>You have received a Nomination Award!</div>
                                            <div><br></div>
                                            <div style="color:#e0212c;font-size:16pt; font-weight:bold;">{{ reasonDisplay(selRecTo.value) }}</div>
                                            <div><br></div>
                                            <div class="preline">{{ selRecTo.message }}</div>
                                        </div>
                                        <div v-else-if="selRecTo.points==0">
                                            <div style="color:#e0212c;font-size:16pt; font-weight:bold;">{{ reasonDisplay(selRecTo.value) }}</div>
                                            <div><br></div>
                                            <div class="preline">{{ selRecTo.message }}</div>
                                        </div>
                                        <div v-else-if="selRecTo.type=='PIF'">
                                            <div>You have received a Pay It Forward award of <strong>{{ selRecTo.points }} NexCredz - {{ reasonDisplay(selRecTo.value) }}</strong></div>
                                            <div><br></div>
                                            <div class="preline">{{ selRecTo.message }}</div>
                                        </div>
                                        <div v-else>
                                            <div>You have been awarded  <strong>{{ selRecTo.points }} NexCredz - {{ reasonDisplay(selRecTo.value) }}</strong></div>
                                            <div><br></div>
                                            <div class="preline">{{ selRecTo.message }}</div>
                                        </div>
                                        <div><br></div>
                                        <div>{{ $store.state.memberStore.member.firstname }}</div>
                                    </div>
                                </div>
                                <!--<div style="background-color:#000" class="text-center">
                                    <img src="../assets/image/email-footlogo.png" class="img-fluid" />
                                </div>-->

                            </div>
                            <div v-else class="recCard">
                                <b>Click on a log entry to view e-card details.</b>
                            </div>
                        </div>
                    </div>
                </div>

            </b-tab>
        </b-tabs>






        
    </div>
   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    import sjData from '@/sjUtilData'


    export default {
        name: 'MyRecognition',
        data() {
            return {
                tabIndex: null,
                isShowMyRewards: true,
                monthList: [],
                selMonth: null,
                isAll: false,
                reportFrom: [],
                reportTo: [],
                isLoading: false,
                selRecFrom: null,
                selRecTo: null,
                bannerFrom: null,
                bannerTo: null,
                msgCountFrom: null,
                msgCountTo: null,
                rewardCountFrom: null,
                rewardCountTo: null,
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member
            },

            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },


        },

        created() {
            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const monthList = [];
            let curYear = new Date().getFullYear();
            let curMonth = new Date().getMonth();

            for (let y = curYear; y >= curYear - 3; y--) {
                let mStart = y == curYear ? curMonth : 11;
                for (let m = mStart; m >= 0; m--) {
                    let t = months[m] + ' ' + y;
                    if (monthList.length === 0) {
                        this.selMonth = t;
                    }
                    monthList.push({ value: t, text: t });
                }
            }
            this.monthList = monthList;

            this.loadReport();
        },
        mounted() {
            //axios.post('/api/member/memberget', {})
            //	.then(({ data }) => {
            //		//this.roles = data.roles;
            //		//this.noms = new sjData(data.noms).data;
            //		//this.displayPart = 1;
            //	})
            //	.catch((error) => {
            //		if (error) {
            //			this.msgBox(error.response.data.message);
            //		}
            //	})
        },
        watch: {
            selMonth: function () {
                if (this.selMonth !== null) {
                    this.loadReport();
                }
            },
            //isAll: function () {
            //    this.loadReport();
            //}
        },

        methods: {
            loadReport() {
                this.isLoading = true;
                this.reportFrom = [];
                this.reportTo = [];
                this.selRecFrom = null;
				this.selRecTo = null;

                axios.post('/api/report/myrecognitions', { selMonth: this.selMonth, doCount: true })
                    .then(({ data }) => {
                        this.isLoading = false;

                        this.reportFrom = (new sjData(data.report).data).filter(rec => rec.isFrom);
                        this.reportTo = (new sjData(data.report).data).filter(rec => !rec.isFrom);

                        this.msgCountFrom = data.msgCountFrom;
                        this.rewardCountFrom = data.rewardCountFrom;
                        this.msgCountTo = data.msgCountTo;
                        this.rewardCountTo = data.rewardCountTo;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },

            reasonDisplay(reason) {
                if (reason == "birthday") {
					return "HAPPY BIRTHDAY"
                } else if (reason == "anniversary") {
                    return "HAPPY WORK ANNIVERSARY";
                } else {
                    return reason;
				}
			},

            recClick(isFrom, rowNum) {
                let selRec = (isFrom ? this.reportFrom : this.reportTo).filter(rec => rec._rowNum === rowNum)[0];
                const reason = selRec.value;
                let bannerImg;
				if (reason == "birthday") {
					bannerImg = "birthday.jpg";
				} else if (reason == "anniversary") {
					bannerImg = "longservice.jpg";
				} else if (selRec.points == 0) {
					bannerImg = "buzz.jpg";
				} else {
					bannerImg = "recognise.jpg";
                }

                //let bannerImg = reason.toLowerCase().sjReplaceAll(" ", "").sjReplaceAll("'", "").sjReplaceAll("!", "") + ".jpg";

                let banner;
                try {
					banner = require("../assets/image/emailpics/" + bannerImg);
				} catch(err) {
					console.log("reasonPic(): Cannot find assets/image/card-" + bannerImg + " - " + err)
                }
                if (isFrom) {
					this.bannerFrom = banner;
                    this.selRecFrom = selRec;
                } else {
					this.bannerTo = banner;
                    this.selRecTo = selRec;
				}
            },

           


            exportToCSV(what) {
                let fx = function (val, fn) {
                    if (typeof fn === "undefined") fn = '';
                    let isNum = (fn === 'num' || typeof val === "number") && !isNaN(val);
                    try {
                        if (isNum) {
                            return val;
                        } else {
                            let s = val.sjReplaceAll("\r\n", " ~ ")
                                .sjReplaceAll("\r", " ~ ")
                                .sjReplaceAll("\n", " ~ ")
                                .sjReplaceAll("\t", "   ")
                                .trim();

                            //ph - phone number - split after 3 chars
                            if (fn == "ph" && s.length > 9) {
                                if (s.substr(0, 1) === "0") {
                                    s = s.sjReplaceAll(' ', '').sjReplaceAll('-', '').sjReplaceAll('(', '').sjReplaceAll(')', '').substr(0, 3) + " " + s.substr(3, 3) + " " + s.substr(6, 4);
                                } else {
                                    s = "'" + s;
                                }
                            }

                            //https://blog.zsec.uk/csv-dangers-mitigations/
                            if (s.indexOf("@") === 1 || s.indexOf("+") === 1 || s.indexOf("-") === 1 || s.indexOf("=") === 1 || s.indexOf("|") === 1 || s.indexOf("%") === 1) {
                                s = "'" + s.sjReplaceAll("|", "\\|");
                            }

                            s = "\"" + s.sjReplaceAll("\"", "\"\"") + "\"";
                            return s;
                        }
                    } catch {
                        return 'Error';
                    }
                }

				let csv = what === 'from' ? 'Type,Date,To,Member No,NexCredz,Value,Message\r\n' : 'Type,Date,From,Member No,NexCredz,Value,Message\r\n';

				let report = what === 'from' ? this.reportTo : this.reportFrom;
                for (var n = 0; n < report.length; n++) {
                    let f = report[n];
                    //debugger;
                    if (what === 'from') {
                        csv += fx(f.type) + ","
                            + fx(f.date.sjToDate('d MMM yyyy HH:mm')) + ','
							+ fx(f.memberName) + ','
							+ fx(f.memberNumber) + ','
                            //+ fx(f.fromDivision) + ','
                            //+ fx(f.to) + ','
                            //+ fx(f.toMemberNo) + ','
                            //+ fx(f.toDivision) + ','
                            + fx(f.points != 0 ? f.points : '') + ','
                            + fx(f.value) + ','
                            + fx(f.message)
                            + '\r\n';
                    } else {
                        csv += fx(f.type) + ","
                            + fx(f.date.sjToDate('d MMM yyyy HH:mm')) + ','
                            //+ fx(f.from) + ','
                            //+ fx(f.fromMemberNumber) + ','
                            //+ fx(f.fromDivision) + ','
							+ fx(f.memberName) + ','
							+ fx(f.memberNumber) + ','
                            //+ fx(f.toDivision) + ','
                            + fx(f.points != 0 ? f.points : '') + ','
                            + fx(f.value) + ','
                            + fx(f.message)
                            + '\r\n';
                    }
                }

                var pom = document.createElement('a');
                pom.href = URL.createObjectURL(new Blob(['\uFEFF', csv], { type: 'text/csv;charset=utf-8;' }));
                pom.setAttribute('download', what === 'from' ? ('Recognition Sent ' + this.selMonth + '.csv') : ('Recognition Received ' + this.selMonth + '.csv'));
                pom.click();
            },
            windowPrint() {
                window.print();
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            }


        }

    }
</script>
