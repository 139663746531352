<template>
    <div>
        <h1>Logout</h1>
        <router-view class="page" />
        <h3>Logged Out</h3>
        <hr />
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        created() {
            this.$store.dispatch('memberStore/doLogout');
			//setTimeout(function () {
			//	window.location.href = "https://auth.nexus.co.za/pkmslogout";
			//}, 2000)
        },
        methods: {
            login() {
				window.location.href = "/sso/idplogin";
                return;
            }
        }
    }
</script>
