import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store/store.js'
import axios from 'axios'
import { sjDebounce } from '@/sjDebounce'
import setupPrototypes from '@/sjUtilPrototypes.js'

setupPrototypes();

//import VueFormulate from '@braid/vue-formulate'

//Vue.use(VueFormulate);

//import './assets/scss/site.scss'

Vue.config.productionTip = false
//function getCookie(cname) {
//    var name = cname + "=";
//    var decodedCookie = decodeURIComponent(document.cookie);
//    var ca = decodedCookie.split(';');
//    for (var i = 0; i < ca.length; i++) {
//        var c = ca[i];
//        while (c.charAt(0) == ' ') {
//            c = c.substring(1);
//        }
//        if (c.indexOf(name) == 0) {
//            return c.substring(name.length, c.length);
//        }
//    }
//    return "";
//}

    //document.cookie = "My_Cookie=; expires=Thu, 01 Jan 2000 00:00:00 UTC; path=/;"; //Delete cookie,

new Vue({
    router,
    store,

    methods: {
        onWindowResize: function () {
            this.$store
                .dispatch('setWindowResized', {
                    windowWidth: window.innerWidth,
                    windowHeight: window.innerHeight
                })
        }
    },

    destroyed() {
        window.removeEventListener('resize', this.onWindowResize);
    },

    created() {
        window.addEventListener('resize', sjDebounce(this.onWindowResize, 125));

        axios.interceptors.request.use(
            request => {
                this.$store.dispatch('setDataLoading', true)
                return request;
            },
            error => {
                this.$store.dispatch('setDataLoading', false)
                return Promise.reject(error)
            }
        );

        //Setup Axios - when any response returns a 401 error, force go to logout
        axios.interceptors.response.use(
            response => {
                //console.log('** Axios_Loaded **');
                this.$store.dispatch('setDataLoading', false)
                return response
            },
            error => {
                this.$store.dispatch('setDataLoading', false)
                const vm = new Vue()
                //console.log('sj_ajax_error');
                if (error.response) {
                    console.log('sj_ajax_error:response - ' + error.response.status + ' - ' + JSON.stringify(error.response));
                    //console.log('Error response.headers: ' + JSON.stringify(error.response.headers));
                    if (error.response.status === 401) {
                        console.log("       > 401 status, so dispatch 'member/logout'.");
                        this.$store.dispatch('memberStore/doSessionLost');
                    } else if (error.response.status === 400 && error.response.data.message) {
                        console.log("       > 400 (BadRequest), so handled by client function'.");
                        return Promise.reject(error);//Do nothing - handled by the client
                    } else if (error.response.data) {
                        if (error.response.data.message) {
                            vm.$bvModal.msgBoxOk(`An unexpected error (${error.response.status}) has occurred:\r\n\r\n${error.response.data.message}`, {
                                title: `Data Response Error: ${error.response.statusText} (${error.response.status})`,
                                variant: 'danger', size: 'xl'
                            });
                        } else {
                            vm.$bvModal.msgBoxOk(`An unexpected error (${error.response.status}) has occurred:\r\n\r\n${typeof error.response.data === 'string' ? error.response.data : JSON.stringify(error.response.data)}`, {
                                title: `Data Response Error: ${error.response.statusText} (${error.response.status})`,
                                variant: 'danger', size: 'xl'
                            });
                        }
                    } else {
                        vm.$bvModal.msgBoxOk(`An unexpected error (${error.response.status}) has occurred:\r\n\r\n${typeof error.response === 'string' ? error.response : JSON.stringify(error.response)}`, {
                            title: `Data Response Error: ${error.response.statusText} (${error.response.status})`,
                            variant: 'danger', size: 'xl'
                        });
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('sj_ajax_error:request - ' + JSON.stringify(error.request));
                    vm.$bvModal.msgBoxOk(`An unexpected error has occurred:\r\n\r\n${JSON.stringify(error.request)}`, {
                        title: `Data Request Error`, size: 'xl', variant: 'danger',
                    });
                } else {
                    console.log('sj_ajax_error:other - ' + JSON.stringify(error));
                    vm.$bvModal.msgBoxOk(`An unexpected error has occurred:\r\n\r\n${JSON.stringify(error)}`, {
                        title: `Data Unexpected Error:`, size: 'xl', variant: 'danger',
                    });
                }

                return Promise.reject()
            }
        );


        let bearerToken = sessionStorage.getItem('SecBr');
        if (bearerToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;
            //let siteTemplate = sessionStorage.getItem('site');

            //Load the data for this page
            this.$store.dispatch('memberStore/getMemberData');
        } else {
            //console.log('main.js - No SecBr token found, so navigate to /login');
            //this.$router.push({ name: 'Login' }) //we use replace instead of push - to replace the current history entry
            //location.href = process.env.BASE_URL + 'logout';
            //this.$store.dispatch('memberStore/doLogin');
        }
    },

    render: h => h(App)

}).$mount('#app')
