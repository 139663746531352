<template>
    <div v-if="isLoggedIn" class="container page">


        <template v-if="displayPart==1">
            <div class="text-right"><a href="#" v-on:click.prevent="formReset">Reset</a></div>
          
        </template>



        <section v-if="displayPart==0" class="card card-default">
            <div class="card-header">
                <h5>Nominate</h5>
            </div>
            <div class="card-body">
                <div><strong>Selected Employees to Nominate:</strong></div>
                <div v-for="r in selectedMembers" :key="r._rowNum" class="row">
                    <template v-if="r.isEntireDivision">
                        <div class="col-1 text-right"><b-icon icon="collection" style="color:#9f34a0"></b-icon></div>
                        <div class="col" style="color:#9f34a0">Entire division: {{ r.division }}</div>
                    </template>
                    <template v-else>
                        <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
                        <div class="col">{{ r.firstname + ' ' + r.surname }}</div>
                        <div class="col">{{ r.division }}</div>
                    </template>
                </div>
                <div class="mt-1">
                    <b-button type="button" pill @click="$bvModal.show('modalChooseMembers')" variant="primary" size="sm" class="mt-2">{{selectedMembers.length===0 ? 'Select' : 'Change'}}  Recipient</b-button>
                    <b-form-invalid-feedback :state="formState.members">{{ formErrors.members }}</b-form-invalid-feedback>
                    <!--<a href="#" @click.prevent="$bvModal.show('modalChooseMembers')">Select Members</a>-->
                </div>


                <hr />
                <b-form @submit.prevent="onSubmitNom" novalidate>

                    <b-form-group id="groupAwardCategory" label-for="selectNomCat" label="Company Value:" :invalid-feedback="formErrors.nomCat" :state="formState.nomCat" description="Select one or more values" v-slot="{ ariaDescribedby }">
                        <b-form-checkbox-group id="selectNomCat" v-model="nomCat" :options="nomCatList" value-field="nomCatValue" text-field="nomCat" :state="formState.nomCat"
                                               :aria-describedby="ariaDescribedby"
                                               name="selectNomCat"></b-form-checkbox-group>
                    </b-form-group>

                    <!-- 1Up -->
                    <b-form-group v-if="true" id="groupAwardCategory" label-for="selectNomCat" label="Other:" :invalid-feedback="formErrors.nomCat" :state="formState.nomCat" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group id="selectNomCat2" v-model="nomCat2" :options="nomCatList2" value-field="nomCatValue" text-field="nomCat" :state="formState.nomCat"
                                               :aria-describedby="ariaDescribedby"
                                               name="selectNomCat"></b-form-radio-group>
                    </b-form-group>

                    <!--<b-form-group id="groupAwardCategory" label-for="selectNomCat" label="Company Value:" :invalid-feedback="formErrors.nomCat" description="">
        <b-form-select v-model="form.nomCat" :options="nomCatList" value-field="nomCatValue" text-field="nomCat" :state="formState.nomCat" />
    </b-form-group>-->
                    <!--<b-form-group id="groupEmail" label-for="txtEmail" label="Email Address:" :invalid-feedback="formErrors.EmailAddress" description="Your email address">
        <b-form-input id="txtEmail" v-model="form.EmailAddress" type="text" :state="formState.EmailAddress" placeholder="Enter your email address" />
    </b-form-group>-->

                    <b-form-group id="groupReason" label-for="txtMessage" label="Reason:" :invalid-feedback="formErrors.reason" :description="'(' + (750-form.reason.length) + ' characters remaining)'">
                        <b-form-textarea id="txtReason" v-model="form.reason" :state="formState.reason" :placeholder="reasonDescription" rows="3" max-rows="5" maxlength="750" />
                    </b-form-group>
                    <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                </b-form>
            </div>
        </section>


        <!--
    **************************************************************************************************************************************************
                                                                M O D A L S
    **************************************************************************************************************************************************
    -->
        <!-- Modal - Members -->
        <b-modal id="modalChooseMembers" title="Select Members" hide-footer size="xl" no-close-on-backdrop>
            <member-select title="Choose who to nominate"
                           selectedTitle="Recipients"
                           :defaultCountryCode="memberClusterCode"
                           :defaultDivisionCode="memberDivisionCode"
                           :selectedMems="selectedMembers"
                           :isAllowWholeDivision="false"
                           :isSingle="false"
                           :showContinue="false"
                           class="mb-3" />
           
            <hr />
            <div class="text-right"><b-button type="submit" variant="primary" @click="$bvModal.hide('modalChooseMembers')">Done</b-button></div>
        </b-modal>


    </div>



</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    //import SitecodeImage from '@/components/SitecodeImage';
    import axios from 'axios'
    //import sjData from '@/sjUtilData'
    import MemberSelect from '@/components/MemberSelect';
    // import SitecodeImage from '@/components/SitecodeImage';


    export default {
        name: 'Nominate',
        components: { MemberSelect },
        data() {
            return {
                displayPart: 0,
                formInit: {},
                form: { nomCat: [], reason: '', members: [] },
                formErrors: {},
                formState: {},


                selectedMembers: [],
                nomCatList: [],
                nomCatList2: [],
                nomCat: [],
                nomCat2: ""

            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
            memberClusterCode() {
                return this.$store.state.memberStore.member.clusterCode
            },
            memberDivisionCode() {
                return this.$store.state.memberStore.member.divisionCode
            },
            reasonDescription() {
                const valueDesc = "Provide a reason - Your nomination and reason should reference the values you’re nominating the person/team for, with clear and concise examples or stories which illustrate that value. The three most important things to remember when writing a nomination are: What did the nominee do? How did they do it? What were the results or the impact?” The reason will be displayed on the e-card sent to your nominated employee.";
                const otherDesc = "Provide a reason – Describe the contribution made by the individual/team during the 1UP journey. The three most important things to remember when writing a nomination are: What did the individual/team do? How did they overcome challenges or find innovative solutions? What were the results or the impact? The reason will be displayed on the e-card sent to the nominee.";

                return this.nomCat2 !== "" ? otherDesc : valueDesc;
            }
        },
        created() {
            this.formInit = { ...this.form }; //Create a copy of the form init values, so we can use this to reset the form
            //Integrity, Teamwork, Diversity, Innovation, Accountability, Excellence
            this.nomCatList = [
                //{ nomCatValue: null, nomCat: '-- Please select a Value --', disabled: true },
                { nomCatValue: 'Integrity', nomCat: 'Integrity' },
                { nomCatValue: 'Teamwork', nomCat: 'Teamwork' },
                { nomCatValue: 'Diversity', nomCat: 'Diversity' },
                { nomCatValue: 'Innovation', nomCat: 'Innovation' },
                { nomCatValue: 'Accountability', nomCat: 'Accountability' },
                { nomCatValue: 'Excellence', nomCat: 'Excellence' },
            ]
            this.nomCatList2 = [
                //{ nomCatValue: null, nomCat: '-- Please select a Value --', disabled: true },
                { nomCatValue: '1Up nomination', nomCat: '1Up nomination' },
                //{ nomCatValue: 'Service Excellence', nomCat: 'Service Excellence' },
            ]

            this.preSelectMemberLinkIn();


            this.$store
                .dispatch('getDivisionList', {})
                .then(() => {

                })
                .catch((error) => {
                    this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                })
        },

        mounted() {
            this.formStateUpdate(true);

        },
        watch: {
            nomCat(val) {
                if (val.length !== 0) this.nomCat2 = "";
            },
            nomCat2(val) {
                if (val.length !== 0) this.nomCat = [];
            },
        },
        methods: {

            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },

            formReset() {
                this.form = { ...this.formInit };
                this.displayPart = 0;
                this.selectedMembers = [];
            },

            formStateUpdate(init) {

                var fields = Object.getOwnPropertyNames(this.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (init) {
                        this.formState[field] = null;
                        this.formErrors = {}; //reset formErrors object
                    } else if (typeof this.formErrors[field] === 'undefined' || this.formErrors[field] === '') {
                        this.formState[field] = true;
                        console.log("field: " + field + " " + JSON.stringify(this.formState[field]));
                    } else {
                        this.formState[field] = false;
                        console.log("field: " + field + " false");
                    }
                }
            },

            preSelectMemberLinkIn() {
                //Add member to selected list, if passed in on sessionStorage (tloid)
                let tloidMemberId = sessionStorage.getItem('tloid'); //tloid - temp link out id - i.e. MemberId
                if (tloidMemberId) {
                    sessionStorage.removeItem('tloid');
                    axios.post('/api/site/getmemberget', { memberId: tloidMemberId })
                        .then(({ data }) => {
                            this.selectedMembers.push({
                                _serialNo: -1,
                                division: data.companyName,
                                firstname: data.firstName,
                                surname: data.surname,
                                isManager: false,
                                memberId: data.memberId,
                                memberNumber: data.memberNumber,
                                isEntireDivision: false,
                                entireDivisionCode: ''
                            });
                        })
                        .catch((error) => {
                            if (error) {
                                this.msgBox('Error retrieving member data');
                            }
                        })
                }
            },

            onSubmitNom() {
                this.form.members = this.selectedMembers.map(nominee => nominee.isEntireDivision ? 'div:' + nominee.entireDivisionCode : nominee.memberId)

                this.formStateUpdate(true);
                this.form.nomCat = this.nomCat2 === "" ? this.nomCat : [this.nomCat2];
                return axios
                    .post(
                        '/api/nom/newnom',
                        this.form
                    )
                    .then(() => {
                        this.formReset();
                        this.msgBox('Successfully submitted nomination');
                    })
                    .catch((error) => {
                        if (typeof error === 'undefined') return;
                        console.log('sj_error in nominate.onSubmitNom() - ' + JSON.stringify(error));
                        this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                        if (error.response.data.formErrors) {
                            this.formErrors = error.response.data.formErrors;
                            this.formStateUpdate(false);
                        }
                    })
                    ;
            }
        }


    }
</script>
