<template v-if="isLoggedIn">
    <div class="container page">

        <div class="card card-default" style="min-width:420px">
            <div class="card-header"><h3>WALL OF FAME</h3></div>
            <div class="card-body">
                <content-db contentKey="topmsgwalloffame" :isCache="true" v-on:title-onset="contentTitle=$event"></content-db>
                <div class="text-center mt-3">

                    <template v-if="report.length===0">
                        <small><i>No entries on the wall of fame. Watch this space!</i></small>
                    </template>
                    <template v-else>
                        <div class="row">
                            <div v-for="n in report" :key="n._rowNum" class="col-sm-3 p-3">
                                <div class="border rounded p-2">
                                    <div class="text-center"><img class="img-fluid" :src="('/profilepic/' + n.picId)" /></div>
                                    <div class="text-center"><b>{{n.memberName}}</b></div>
                                    <div class="text-center"><small>{{n.division}}</small></div>
                                    <!--<div class="text-center"><b><i>{{n.nomcat}}</i></b></div>
                        <div class="text-center"><i>{{n.dateConcluded.sjToDate('MMMM yyyy')}}</i></div>-->
                                </div>
                            </div>

                            


                        </div>
                        


                    </template>



                </div>




                <div v-if="isLoading"><i>Loading...</i></div>


                <div><b><label ID="lblMessage" runat="server" Text="" ForeColor="#ff0000" /></b></div>
            </div>
        </div>



    </div>

   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    import sjData from '@/sjUtilData'
    import ContentDb from '@/components/ContentDb';



    export default {
        name: 'WallOfFame',
        components: { ContentDb },
        data() {
            return {
                monthList: [],
                selMonth: null,
                isAll: false,
                report: [],
                isLoading: false
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member
            },

            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
        },

        created() {
            this.wallOfFame();
        },
        mounted() {
           
        },
		watch: {
			
		},

        methods: {
            wallOfFame() {
                axios.post('/api/nom/wallOfFame', {})
                    .then(({ data }) => {
                        this.report = new sjData(data.report).data;
                    })
                    .catch((error) => {
                        if (error) {
                            this.msgBox('An unexpected error occured');
                            //this.msgBox(error.response.data.message);
                        }
                    })
            },


            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            }


        }

    }
</script>
