<template v-if="isLoggedIn">
    <div class="container-fluid page">
        <div class="container">
            <section class="card card-default mb-4">
                <div class="card-header">
                    <h4>Competition Setup</h4>
                </div>
                <div class="card-body">
                    <b-form @submit.prevent="onCompetitionUpdate" novalidate>
                        <!--<b-alert v-model="alertMessageVisible" :variant="alertVariant" dismissible>{{ alertMessage }}</b-alert>-->

                        <div class="row">
                            <div class="col-sm">
                                <b-form-group id="groupStartDate" label-for="txtStartDate" label="Competition Start Date:" :invalid-feedback="fComp.errors.startDate" description="">
                                    <b-form-input id="txtStartDate" v-model="fComp.form.startDate" :state="fComp.state.startDate" type="date" placeholder="Date From" format="\d{4}-\d{2}-\d{2}" />
                                </b-form-group>
                            </div>
                            <div class="col-sm">
                                <b-form-group id="groupEndDate" label-for="txtEndDate" label="Competition End Date:" :invalid-feedback="fComp.errors.endDate" description="">
                                    <b-form-input id="txtEndDate" v-model="fComp.form.endDate" :state="fComp.state.endDate" type="date" placeholder="Date To" format="\d{4}-\d{2}-\d{2}" />
                                </b-form-group>
                            </div>
                        </div>

                        <b-form-group id="groupTitle" label-for="txtTitle" label="Competition Title:" :invalid-feedback="fComp.errors.title" description="">
                            <b-form-input id="txtTitle" v-model="fComp.form.title" type="text" :state="fComp.state.title" placeholder="Competition Title" maxlength="50" />
                        </b-form-group>

                        <b-form-group id="groupDescription" label-for="txtDescription" label="Description:" :invalid-feedback="fComp.errors.description" description="">
                            <b-form-textarea id="txtDescription" v-model="fComp.form.description" :state="fComp.state.description" placeholder="Enter a short Description" rows="2" max-rows="16" maxlength="4000" />
                        </b-form-group>

                        <b-form-group id="groupTextboxTitle" label-for="txtTextboxTitle" label="Text Entry Header:" :invalid-feedback="fComp.errors.textboxTitle" description="">
                            <b-form-input id="txtTextboxTitle" v-model="fComp.form.textboxTitle" type="text" :state="fComp.state.textboxTitle" placeholder="Enter a header for the text entry box" maxlength="50" />
                        </b-form-group>

                        <b-form-group id="groupTextboxDescription" label-for="txtDescription" label="Text Entry Description:" :invalid-feedback="fComp.errors.textboxDescription" description="">
                            <b-form-textarea id="txtTextBoxDescription" v-model="fComp.form.textboxDescription" :state="fComp.state.textboxDescription" placeholder="Enter a description for the text entry box" rows="2" max-rows="16" maxlength="2000" />
                        </b-form-group>

                        <div>
                            <b-form-checkbox id="chkisEnabled" switch v-model="fComp.form.isEnabled" :state="fComp.state.isEnabled">Competition Active</b-form-checkbox>
                        </div>

                        <div class="text-right"><b-button type="submit" variant="primary">Update</b-button></div>
                    </b-form>



                </div>
            </section>

            <section class="card mb-5">
                <div class="card-header">Banner</div>
                <div class="card-body">
                    <div class="text-center">
                        <img id="imgProfilePic" :src="'api/competition/competitionbanner?d=' + picSfx" style="width:100%;" />
                    </div>
                    <div>
                        <div>Upload Competition Banner</div>
                        <div>
                            <b-form-file v-model="fileCompetitionBanner"></b-form-file>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="card card-default" style="min-width:420px">
            <div class="card-header">
                <div class="row">
                    <div class="col-sm-6 font-weight-bold">
                        <h5>Competition Report</h5>
                    </div>

                    <div class="col-sm-3">

                    </div>
                    <div class="col-sm-3 text-right">
                        <b-button class="d-print-none mr-2" size="sm" @click.prevent="windowPrint" :disabled="report.length==0">Print</b-button>
                        <b-button class="d-print-none" size="sm" @click.prevent="exportToCSV" :disabled="report.length==0">Export to CSV</b-button>
                    </div>
                </div>

            </div>
            <div class="card-body">
                <div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-md-2 col-lg-1 control-label">From Date:</label>
                        <div class="col-sm-3 col-md-4 col-lg-3">
                            <b-input-group size="sm">
                                <b-input-group-prepend is-text>
                                    <b-icon icon="calendar"></b-icon>
                                </b-input-group-prepend>
                                <b-form-select :options="monthList" v-model="selMonthFrom">
                                    <!--<template v-slot:first>
                                    <b-form-select-option :value="null">-- All Periods --</b-form-select-option>
                                </template>-->
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <label class="col-sm-3 col-md-2 col-lg-1 control-label">To Date:</label>
                        <div class="col-sm-3 col-md-4 col-lg-3">
                            <b-input-group size="sm">
                                <b-input-group-prepend is-text>
                                    <b-icon icon="calendar"></b-icon>
                                </b-input-group-prepend>
                                <b-form-select :options="monthList" v-model="selMonthTo">
                                    <!--<template v-slot:first>
                                    <b-form-select-option :value="null">-- All Periods --</b-form-select-option>
                                </template>-->
                                </b-form-select>
                            </b-input-group>
                        </div>
                    </div>
                    <!--<div class="form-group row">
                    <label class="col-sm-3 col-md-2 col-lg-1 control-label">Status:</label>
                    <div class="col-sm-7 col-md-7">
                        <b-form-radio-group id="selAwardStatus" v-model="awardStatus">
                            <b-form-radio value="W">Winners</b-form-radio>
                            <b-form-radio value="R">Runner-Ups</b-form-radio>
                            <b-form-radio value="C">Concluded</b-form-radio>

                            <b-form-radio value="N">Awaiting Decision</b-form-radio>
                        </b-form-radio-group>
                    </div>
                    <div class="col-sm-2 col-md-3">

                    </div>
                </div>-->
                    <div class="row d-print-none">
                        <div class="col-sm-3 col-md-2 col-lg-1"></div>
                        <div class="col-sm-7 col-md-7">
                            <b-button @click="loadReport()" variant="primary">Load Report</b-button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <table v-if="hasRunOnce && !isLoading" class="table table-borderless" style="font-size:0.8rem">
            <tr>
                <th colspan="2">Nomination</th>
                <th colspan="2">Review</th>
            </tr>

            <tbody v-for="f in report" :key="f._rowNum">
                <tr>
                    <td colspan="4"><hr /></td>
                </tr>
                <tr>
                    <td><b>Ref:</b></td>
                    <td>{{ f.nomSerialNum + '/' + f.nomId }} - {{ f.dateCreated.sjToDate('d MMM yyyy HH:mm') }}</td>
                    <td><b>Review By:</b></td>
                    <td>{{ f.reviewByMemberNumber + ' - ' + f.reviewByMember + ' ' + f.reviewByDivision }}</td>
                </tr>
                <tr>
                    <td><b>Nominee:</b></td>
                    <td>{{ f.nomineeMemberNumber + ' - ' + f.nomineeMember + ' ' + f.nomineeDivision }}</td>
                    <td><b>Rating:</b></td>
                    <td>{{ f.reviewRating + ' / 7' }}</td>
                </tr>
                <tr>
                    <td><b>Nominated for:</b></td>
                    <td>{{ f.nomCat }}</td>
                    <td><b>Points:</b></td>
                    <td>{{ f.reviewPointsValue }}</td>
                </tr>
                <tr>
                    <td><b>Reason:</b></td>
                    <td class="preline">{{ f.reason }}</td>
                    <!--<td><b>Quarterly Motivation:</b></td>
                    <td class="preline">{{ f.quarterlyMotivation }}</td>-->
                </tr>
                <tr>
                    <td><b>Nominated By:</b></td>
                    <td>{{  f.byMemberNumber + ' - ' + f.byMember + ' ' + f.byDivision }}</td>
                </tr>
            </tbody>


        </table>
        <div v-if="isLoading"><i>Loading...</i></div>
        <div v-if="hasRunOnce && !isLoading && report.length==0"><i>No Results</i></div>

    </div>

   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    import sjData from '@/sjUtilData'


    export default {
        name: 'ReportQuarterlyAwards',
        data() {
            return {
                fComp: {
                    form: { startDate: '', endDate: '', title: '', description: '', textboxTitle: '', textboxDescription: '', isEnabled: false, }
                },
                monthList: [],
                selMonthFrom: null,
                selMonthTo: null,
                awardStatus: 'W',
                report: [],
                isLoading: false,
                hasRunOnce: false,
                fileCompetitionBanner: null,
                picTempSrc: null,
                picSfx: ((new Date).getTime() - 1),


            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member
            },

            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },

        },

        created() {
            this.formSetState(this.fComp, true, true) //formObj, resetState, resetForm


            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const result = [];
            let curYear = new Date().getFullYear();
            let curMonth = new Date().getMonth();

            for (let y = curYear; y >= curYear - 3; y--) {
                let mStart = y == curYear ? curMonth : 11;
                for (let m = mStart; m >= 0; m--) {
                    let t = months[m] + ' ' + y;
                    if (result.length === 0) this.selMonthFrom = t;
                    if (result.length === 0) this.selMonthTo = t;
                    result.push({ value: t, text: t });
                }
            }
            this.monthList = result;
            //this.loadReport();
        },
        mounted() {

            axios.post('/api/competition/admincompetitionget', {})
                .then(({ data }) => {
                    console.log("comp get");
                    this.formSetState(this.fComp, true, true);//formObj, resetState, resetForm
                    let d = data.competition;
                    console.log(d);
                    var fields = Object.getOwnPropertyNames(this.fComp.form);

                    for (let i = 0; i < fields.length; i++) {
                        let field = fields[i];
                        console.log(field)
                        this.fComp.form[field] = d[field];
                    }
            	})
            	.catch((error) => {
            		if (error) {
                        this.msgBox('Error retrieving member data');
            		}
            	})
        },
         watch: {
             fileCompetitionBanner: function (newFile) {
                 if (newFile !== null) {
                     this.doFileUpload();
                 }
             },
         },

        methods: {
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },
            doFileUpload() {
                let formData = new FormData();
                formData.append('filePic', this.fileCompetitionBanner, this.fileCompetitionBanner.name);
                axios.post('/api/competition/adminbannerupload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then(() => {
                        this.picSfx = ((new Date).getTime() - 1);
                    })
                    .catch(function () {
                        alert('FAILURE UPLOAD!!');
                    });
            },
            onCompetitionUpdate() {
                this.formSetState(this.fComp, true);//formObj, resetState, resetForm

                return axios
                    .post(
                        '/api/competition/admincompetitionupdate',
                        this.fComp.form
                    )
                    .then(({ data }) => {
                        this.msgBox('Competition Settings Updated.');
                    })
                    .catch((error) => {
                        if (error && error.response) {
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                            if (error.response.data.formErrors) {
                                this.fComp.errors = error.response.data.formErrors;
                                this.formSetState(this.fComp);
                            }
                        } else {
                            this.msgBox('Unexpected Error');
                        }
                    });
            },

            loadReport() {
                this.isLoading = true;
                this.report = [];
                axios.post('/api/competition/competitionreport', { selMonthFrom: this.selMonthFrom, selMonthTo: this.selMonthTo, awardStatus: this.awardStatus })
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.hasRunOnce = true;
                        this.report = new sjData(data.report).data;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },
            exportToCSV() {
                let fx = function (val, fn) {
                    if (typeof fn === "undefined") fn = '';
                    let isNum = (fn === 'num' || typeof val === "number") && !isNaN(val);
                    try {
                        if (isNum) {
                            return val;
                        } else {
                            let s = val.sjReplaceAll("\r\n", " ~ ")
                                .sjReplaceAll("\r", " ~ ")
                                .sjReplaceAll("\n", " ~ ")
                                .sjReplaceAll("\t", "   ")
                                .trim();

                            //ph - phone number - split after 3 chars
                            if (fn == "ph" && s.length > 9) {
                                if (s.substr(0, 1) === "0") {
                                    s = s.sjReplaceAll(' ', '').sjReplaceAll('-', '').sjReplaceAll('(', '').sjReplaceAll(')', '').substr(0, 3) + " " + s.substr(3, 3) + " " + s.substr(6, 4);
                                } else {
                                    s = "'" + s;
                                }
                            }

                            //https://blog.zsec.uk/csv-dangers-mitigations/
                            if (s.indexOf("@") === 1 || s.indexOf("+") === 1 || s.indexOf("-") === 1 || s.indexOf("=") === 1 || s.indexOf("|") === 1 || s.indexOf("%") === 1) {
                                s = "'" + s.sjReplaceAll("|", "\\|");
                            }

                            s = "\"" + s.sjReplaceAll("\"", "\"\"") + "\"";
                            return s;
                        }
                    } catch {
                        return 'Error';
                    }
                }

                let csv = 'Ref,Date,Nominee ID,Nominee Name,Nominee Division,Nominated For,Reason,Nominated By ID,Nominated By,Nominated By Division,Review By ID,Review By Name,Review By Division,Rating,Points,Quarterly Motivation\r\n';

                for (var n = 0; n < this.report.length; n++) {
                    let f = this.report[n];
                    //debugger;
                    csv += f.nomSerialNum + '/' + f.nomId + ','
                        + fx(f.dateCreated.sjToDate('d MMM yyyy HH:mm')) + ','
                        + fx(f.nomineeMemberNumber) + ','
                        + fx(f.nomineeMember) + ','
                        + fx(f.nomineeDivision) + ','
                        + fx(f.nomCat) + ','
                        + fx(f.reason) + ','
                        + fx(f.byMemberNumber) + ','
                        + fx(f.byMember) + ','
                        + fx(f.byDivision) + ','
						+ fx(f.reviewByMemberNumber) + ','
						+ fx(f.reviewByMember) + ','
						+ fx(f.reviewByDivision) + ','
                        + fx(f.reviewRating) + ','
                        + fx(f.reviewPointsValue === 0 ? '' : f.reviewPointsValue) + ','
                        + fx(f.quarterlyMotivation) + '\r\n';
                }

                var pom = document.createElement('a');
                pom.href = URL.createObjectURL(new Blob(['\uFEFF', csv], { type: 'text/csv;charset=utf-8;' }));
                pom.setAttribute('download', 'report.csv');
                pom.click();
            },
            windowPrint() {
                window.print();
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            }


        }

    }
</script>
