<template>
    <header v-if="isLoggedIn" class="topHead container-fluid d-print-none">
		<div class="row">
			<div class="col-md-3 topHeadLeft text-center d-flex align-items-center justify-content-center py-3 px-3 px-lg-5">
				<img src="../assets/image/topHeadLogoLeft.png" class="img-fluid" />
			</div>

			<div class="col-md-6 topHeadLeft">

				<!-- All other clusters -->
				<div class="row">
					<div class="col-lg-2"></div>
					<div class="col-lg-3 my-1 my-lg-2 text-center text-lg-right">
						<!-- All other clusters -->
						<a href="memberdetailsupdate" title="Change profile picture or update details">
							<img id="imgProfilePic" :src="profilePicSrc" class="headprofilepic img-fluid" />
						</a>
					</div>

					<div class="col-lg-7 my-1 my-lg-5">
						<div class="mt-2 text-lg-left text-center"><span class="headHelloName" id="headHelloName">Hello {{ firstname }}</span></div>
						<div class="headPoints mt-4 text-lg-left text-center" id="headHelloPointsRedeem"><span style="color:#00b3e4; font-size:1.3em; line-height:1.3em;">&bull;&nbsp;</span> <strong>{{ $store.state.memberStore.member.awardsPointsAvailable + ' ' + $store.state.memberStore.member.pointsName }}</strong>  to redeem</div>
						<div v-if="$store.state.memberStore.member.isManager" class="headPoints mt-1 text-lg-left text-center" id="headHelloPointsRecognise"><span style="color:#00b3e4; font-size:1.3em; line-height:1.3em;">&bull;&nbsp;</span><strong>{{ $store.state.memberStore.member.awardsPointsAvailableMng + ' ' + $store.state.memberStore.member.pointsName }}</strong> to award</div>
					</div>

				</div>


			</div>
			<div class="col-md-3 topHeadRight text-center order-first order-md-last d-flex align-items-center justify-content-center py-3 px-3 px-lg-5">
				<!--<img :src="imgUrl" class="img-fluid w-100" />-->
				<img src="../assets/image/topHeadLogoRight.png" class="img-fluid" />
			</div>
		</div>
    </header>
    <header v-else class="topHead container-fluid">
        <div class="topHeadLeft">
            <div class="py-5" style="color:white; font-weight:bold;"></div>
        </div>
    </header>
</template>

<script>
	//
	//import { mapState } from 'vuex'
	//import SitecodeImage from '@/components/SitecodeImage';
	export default {
		name: 'AppHeader',
		components: {
			//'sitecode-image': SitecodeImage
		},
		computed: {
			windowWidth() {
				return this.$store.state.windowWidth
			},
			isLoggedIn() {
				return this.$store.state.isLoggedIn
			},
			rootFolder() {
				return process.env.BASE_URL
			},
			//        imgUrl() {
			////return '../assets/image/topHeadLogo_' + this.$store.state.siteTemplate + '.png';
			//return require('../assets/image/topHeadLogo_' + this.$store.state.siteTemplate + '.png');
			//        },
			firstname() {
				return this.$store.state.memberStore.member.firstname
			},//,
			profilePicSrc() {
				return '/profilepic/' + this.$store.state.memberStore.member.picId + this.$store.state.memberStore.profilepicSfx
			},//,
			//...mapState({
			//    member: state => state.memberStore.member
			//})
			awardsPointsAvailable() {
				return this.$store.state.memberStore.member.awardsPointsAvailable + ' ' + this.$store.state.memberStore.member.pointsName;
			},
			awardsPointsAvailable1() {
				return this.$store.state.memberStore.member.awardsPointsAvailable1 + ' ' + this.$store.state.memberStore.member.pointsName;
			},
			awardsPointsAvailableMng() {
				return this.$store.state.memberStore.member.awardsPointsAvailableMng + ' ' + this.$store.state.memberStore.member.pointsName;
			}

		},
		mounted() {
			this.$nextTick(() => {
				this.handleResize();
			});
		},
		updated() {
			this.$nextTick(() => {
				this.handleResize();
			});
		},
		watch: {
			windowWidth() { //newWidth, oldWidth
				//console.log(`width changed to ${newWidth} from ${oldWidth}`);
				this.handleResize();
			}
		},
		methods: {
			handleResize: function () {
				if (this.$store.state.siteTemplate != 'santam') return; //Only Santam needs sizing
				if (!this.$refs.topHeadLogoLeft) {
					console.log('handleResize: cannot find topHeadLogoLeft');
					setTimeout(this.handleResize, 250)
					return;
				}

				let h = this.$refs.topHeadLogoLeft.firstElementChild.offsetHeight; //$('.homeicon1').innerHeight();
				let w = this.$refs.topHeadLogoLeft.firstElementChild.offsetWidth; //$('.homeicon1').innerWidth();

				if (w < 3 || h < 3) {
					//console.log('handleResize: topHeadLogoLeft w or h = 0');
					setTimeout(this.handleResize, 250)
					return;
				}

				let sw = w / 354;
				let sh = h / 298;

				console.log('handleResize: topHeadLogoLeft w: ' + sw + ', h: ' + sh);
				this.$refs.imgProfilePic.style.left = (sw * 122) + "px";
				this.$refs.imgProfilePic.style.top = (sh * 68) + "px";
				this.$refs.imgProfilePic.style.width = (sw * 222) + "px";
				this.$refs.imgProfilePic.style.height = (sh * 222) + "px";
			}
		}
	}
</script>

