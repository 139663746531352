<template>
    <section>
        <footer class="pagefooter clearfix d-print-none mt-5">
            <div>
                <img src="../assets/image/footer.png" class="w-100 d-none d-lg-block" />
                <img src="../assets/image/footerMobi.png" class="w-100 d-lg-none" />
            </div>
            <div>
                <span class="mx-1">&copy; {{ (new Date()).getFullYear() }}</span> |
                <a href="#" class="mx-1" @click.prevent="$bvModal.show('modalCookiePolicyPreferences')">Cookie Preferences</a> |
                <a href="/privacy" class="mx-1">Privacy Policy</a>
            </div>
        </footer>
        
    </section>
</template>

<script>
//import { mapState } from 'vuex'
    export default {
        name: 'AppFooter',
        components: {
        },
        computed: {
			isLoggedIn() {
				return this.$store.state.isLoggedIn
			},
            rootFolder() {
                return process.env.BASE_URL
            }
        }
    }
</script>

