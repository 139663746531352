<template>
    

    <section v-if="isLoggedIn" class="container homePg">
        <div class="mb-3"><!-- <sdj:AdRotator RotatorType="TopAd" runat="server" HideSlideDots="true" /> --></div>
        <div class="mb-3" v-if="isCompetition"><router-link to="/competition" class="hvrlinkbutton"><img id="imgProfilePic" :src="'api/competition/competitionbanner?d=' + picSfx" class="w-100" style="max-height:400px" /></router-link></div>
        <div class="row">
            <div class="col-lg-8">
                <div class="row">
                    <div class="col-lg-6 mb-4 ">
                        <router-link to="/nominate" class="hvrlinkbutton"><span ref="homeicon1"><img src="../assets/image/homeNominate.png" class="img-fluid homeIcon1" /></span></router-link>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <router-link to="/rewards" class="hvrlinkbutton"><img src="../assets/image/homeRedeem.png" class="img-fluid" /></router-link>
                    </div>
                    <div class="col-lg-6 mb-4" runat="server" id="divLinkRewards">
                        <router-link to="/myrecognition" class="hvrlinkbutton"><img src="../assets/image/homeMyRecognition.png" class="img-fluid" /></router-link>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <router-link to="/rewardpayitforward" class="hvrlinkbutton"><img src="../assets/image/homePayItForward.png" class="img-fluid" /></router-link>
                    </div>
                    <div class="col-lg-6 mb-4">

                        <div class="homeCelebrations" ref="homeCelebrations">
                            <div class="homeRightTitle" ref="homeRightTitle"><router-link to="/celebrations">Today's Celebrations</router-link></div>

                            <!-- <div id="homeGtkCarousel" class="carousel slide" data-ride="carousel" data-interval="3700">
                        <div class="carousel-inner" role="listbox" id="homeGtkCarouselSlides">  -->
                            <b-carousel id="homeCelebrationsCarousel" :interval="2700" controls
                                        img-width="1024"
                                        img-height="480">
                                <!--
                            controls
                            indicators
                            v-model="slide"
                            :interval="3700"
                            background="#ababab"
                            img-width="1024"
                            img-height="480"
                            style="text-shadow: 1px 1px 2px #333;"
                            @sliding-start="onSlideStart"
                            @sliding-end="onSlideEnd"
                            -->
                                <template>
                                    <b-carousel-slide v-for="item in celebrations" :key="item._rowNum">
                                        <template v-slot:img>
                                            <!-- + (isActiveDone ? " : " active")     class="homeGtkSlide carousel-item" -->
                                            <div class="row no-gutters align-items-center homeCelebrationsSlide">
                                                <div class="col-4 homeGtkPic">
                                                    <div class="ml-3 mr-2">
                                                        <img class="w-100" :src="('/profilepic/' + item.memberId)" /><!-- + profileNum >-->
                                                    </div>
                                                </div>
                                                <div class="col-8">
                                                    <div class="ml-1 mr-2">
                                                        <div class="homeGtkName">{{ item.memberName }}</div>
                                                        <!--<div class="homeGtkPos">{{ item.division }}</div>-->
                                                        <div class="homeGtkData">{{ item.what === "BDAY" ? "Birthday" : "Work Anniversary"  }}</div>
                                                        <div class="text-center my-3"><router-link to="/celebrations" class="btn btn-secondary btn-sm">Celebrate Now</router-link></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </b-carousel-slide>
                                </template>
                            </b-carousel>
                            <!-- <div class="text-right homeGtkLink"><a href="gettoknow">MORE</a></div>-->
                        </div>


                        <!--<router-link to="/celebrations" class="hvrlinkbutton"><img src="../assets/image/homeTodaysCelebrations.png" class="img-fluid" /></router-link>-->
                    </div>
                    <div class="col-lg-6 mb-4">
                        <router-link to="/walloffame" class="hvrlinkbutton"><img src="../assets/image/homeWallOfFame.png" class="img-fluid" /></router-link>
                    </div>

                    <div class="col-lg-6 mb-4">
                        <router-link to="/ideabox" class="hvrlinkbutton"><img src="../assets/image/homeIdea.png" class="img-fluid" /></router-link>
                    </div>

                    <div class="col-lg-6 mb-4">

                        <div class="homeGetToKnow" ref="homeGetToKnow">
                            <div class="homeRightTitle" ref="homeRightTitle"><router-link to="/gettoknow">Get to know</router-link></div>

                            <!-- <div id="homeGtkCarousel" class="carousel slide" data-ride="carousel" data-interval="3700">
                        <div class="carousel-inner" role="listbox" id="homeGtkCarouselSlides">  -->
                            <b-carousel id="homeGtkCarousel" :interval="3700" controls
                                        img-width="1024"
                                        img-height="480">
                                <!--
                            controls
                            indicators
                            v-model="slide"
                            :interval="3700"
                            background="#ababab"
                            img-width="1024"
                            img-height="480"
                            style="text-shadow: 1px 1px 2px #333;"
                            @sliding-start="onSlideStart"
                            @sliding-end="onSlideEnd"
                            -->
                                <template>
                                    <b-carousel-slide v-for="item in getToKnow" :key="item._rowNum">
                                        <template v-slot:img>
                                            <!-- + (isActiveDone ? " : " active")     class="homeGtkSlide carousel-item" -->
                                            <div class="row no-gutters align-items-center homeGtkSlide">
                                                <div class="col-4 homeGtkPic">
                                                    <div class="ml-3 mr-2">
                                                        <img class="w-100" :src="('/profilepic/' + item.memberId)" /><!-- + profileNum >-->
                                                    </div>
                                                </div>
                                                <div class="col-8">
                                                    <div class="ml-1 mr-2">
                                                        <div class="homeGtkName">{{ item.memberName }}</div>
                                                        <div class="homeGtkPos">{{ item.natureOfBusiness }}</div>
                                                        <div class="homeGtkData" v-if="item.interestOtherDesc"><b>Likes:</b> {{ item.interestOtherDesc }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </b-carousel-slide>
                                </template>
                            </b-carousel>
                            <!-- <div class="text-right homeGtkLink"><a href="gettoknow">MORE</a></div>-->
                        </div>
                    </div>

                    <!--<div class="col-lg-6 mb-4" id="tooltip-target-1">
                    <router-link to="/innovativeidea" class="hvrlinkbutton"><img src="../assets/image/homeInnovativeIdeas.png" class="img-fluid" /></router-link>
                </div>
                <b-tooltip target="tooltip-target-1" triggers="hover">
                    <h3>Watch Out For This!</h3>
                </b-tooltip>-->
                </div>
            </div>
            <div class="col-lg-4">
                <div class="homeShoutout d-flex flex-column mb-4" ref="homeShoutout">
                    <div class="homeRightTitle mb-1">Recent Nominations</div>
                    <div class="shoutouts align-self-stretch" id="divShoutOutData">
                        <div v-for="item in recentRecognition" :key="item._rowNum" class="shoutoutitem">
                            <div class="shoutto">{{ item.memberName }}</div>
                            <div>{{ item.value }}</div>
                        </div>
                    </div>
                    <!--<div class="text-center my-3"><router-link to="/recentrecognition" class="btn btn-secondary btn-sm">View More</router-link></div>-->
                </div>


            </div>
        </div>
        <!--
    <section class="row hellostats">
        <div class="col-4 col-lg-2 hellostatslg" id="divRewardsRedeemed">{{ rewardsRedeemed }}&nbsp;</div>
        <div class="col-8 col-lg-2 hellostats">Rewards <span class="statsbr"><br /></span>Redeemed</div>
        <div class="clearfix visible-sm-block"></div>
        <div class="col-4 col-lg-2 hellostatslg" id="divEcardsSent">{{ messagesSent }}&nbsp;</div>
        <div class="col-8 col-lg-2 hellostats">eCards <span class="statsbr"><br /></span>Sent</div>
        <div class="clearfix visible-sm-block"></div>
        <div class="col-4 col-lg-2 hellostatslg" id="divRewardsIssued">{{ rewardsIssued }}&nbsp;</div>
        <div class="col-8 col-lg-2 hellostats">Rewards <span class="statsbr"><br /></span>Issued</div>
    </section>
    -->
    </section>



    <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    //import SitecodeImage from '@/components/SitecodeImage';


    export default {
        name: 'Home',
		components: {}, //SitecodeImage 
        computed: {
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
			memberClusterCode() {
				return this.$store.state.memberStore.member.clusterCode
			},
			memberDivisionCode() {
				return this.$store.state.memberStore.member.divisionCode
			},
            isManager() {
                return this.$store.state.memberStore.member.isManager
            },
            recentRecognition() {
                return this.$store.state.memberStore.homeRecentRecognition
            },
            getToKnow() {
                return this.$store.state.memberStore.homeGetToKnow
            },
			celebrations() {
				return this.$store.state.memberStore.homeCelebrations
			},
            messagesSent() {
                return this.$store.state.memberStore.homeStats.messagesSent
            },
            rewardsIssued() {
                return this.$store.state.memberStore.homeStats.rewardsIssued
            },
            rewardsRedeemed() {
                return this.$store.state.memberStore.homeStats.rewardsRedeemed
            },
            isCompetition() {
                return this.$store.state.memberStore.isCompetition
            },
            windowWidth() {
                return this.$store.state.windowWidth
            }

        },
        mounted() {
            this.$nextTick(() => {
                //console.log('home.vue next tick mounted');
                this.handleResize();
                //setTimeout(this.handleResize, 5000);
            });
        },
        updated() {
            this.$nextTick(() => {
                //console.log('home.vue next tick updated');
                this.handleResize();
                //setTimeout(this.handleResize, 5000);
            });
        },
        watch: {
			windowWidth() { //newWidth, oldWidth
                //console.log(`width changed to ${newWidth} from ${oldWidth}`);
                this.handleResize();
            }
        },
        methods: {
            handleResize: function () {
                if (!this.$refs.homeicon1 || !this.$refs.homeicon1.firstElementChild) {
                    //console.log('handleResize: cannot find homeicon1');
                    setTimeout(this.handleResize, 250)
                    return;
                }

                let h = this.$refs.homeicon1.firstElementChild.offsetHeight; //$('.homeicon1').innerHeight();
                let w = this.$refs.homeicon1.firstElementChild.offsetWidth; //$('.homeicon1').innerWidth();
                if (w < 50 || h < 50) {
					//console.log('handleResize: homeicon1 w or h = 0');
					setTimeout(this.handleResize, 250)
					return;
                }
				//console.log('handleResize: homeicon1 w: ' + w + ', h: ' + h);

                this.$refs.homeShoutout.style.height = (h * 4 + 24*3) + "px";
                this.$refs.homeShoutout.style.width = w + "px";
                this.$refs.homeGetToKnow.style.height = h + "px";
                this.$refs.homeGetToKnow.style.width = w + "px";
				this.$refs.homeCelebrations.style.height = h + "px";
				//this.$refs.homeCelebrations.style.width = w + "px";

                let titleHeight = this.$refs.homeRightTitle.offsetHeight;
                var homeGtkSlide = document.getElementsByClassName('homeGtkSlide');

                for (var i = 0; i < homeGtkSlide.length; i++) {
                    homeGtkSlide.item(i).style.height = (h - titleHeight) + "px"
                }

                var homeCelebrationsSlide = document.getElementsByClassName('homeCelebrationsSlide');
                for (var i = 0; i < homeCelebrationsSlide.length; i++) {
                    homeCelebrationsSlide.item(i).style.height = (h - titleHeight) + "px"
                }
            }
        }
    }
</script>
